<template>
  <div
    v-click-outside="{
      exclude: ['select'],
      handler: 'close',
    }"
    ref="selectWrapper"
    :class="['select__wrapper', { 'select__wrapper--required': required }]"
  >
    <label v-if="label" class="select__label">{{ label }}</label>
    <div class="select__inner-wrapper">
      <input
        ref="select"
        class="select"
        :placeholder="currentPlaceholder"
        readonly
        :disabled="disabled"
        @click="toggle"
        @keydown.enter.prevent="onKeyDown($event)"
      />
      <span
        v-if="clearable && selectedOptions.length"
        @click="clearSelect"
        class="select__icon-clear"
        >x</span
      >
      <span class="select__icon-button">▾</span>
    </div>

    <transition name="fade">
      <div
        ref="dropdown"
        v-show="isOpen"
        :class="{ 'select__dropdown--open': isOpen }"
        class="select__dropdown"
      >
        <slot name="options-header"></slot>
        <slot name="options-body">
          <input
            v-show="search || isLargeList"
            v-model.trim="searchValue"
            ref="search"
            class="select__search-input"
            type="text"
            @input="$emit('sm-select:search', searchValue)"
            @keydown.esc.prevent="toggle"
          />
          <small v-show="isLargeList">
            *список слишком большой, введите название.
          </small>
          <ul
            ref="options"
            class="select__list"
            @mousemove="onOptionsMouseMove()"
          >
            <li
              v-if="multiselect && options.length && !hideSelectAll"
              class="select__item"
              :class="{
                'select__item--mouseover':
                  keySelection && mouseOverIndex === -1,
                'select__item--hover-disabled':
                  keySelection && mouseOverIndex !== -1,
              }"
              @click="onSelectAll"
              @mouseover="onMouseOver(-1)"
            >
              <sm-checkbox
                :modelValue="isSelectedAll"
                class="select__item-checkbox"
              />
              Выбрать все
            </li>
            <li
              v-for="(option, index) in filteredOptions.slice(0, 50)"
              :key="index"
              :class="{
                'select__item--selected': computedSelectedOption(option),
                'select__item--mouseover':
                  keySelection && mouseOverIndex === index,
                'select__item--hover-disabled':
                  keySelection && mouseOverIndex !== index,
              }"
              class="select__item"
              @click="onSelect(option, index)"
              @mouseover="onMouseOver(index)"
            >
              <sm-checkbox
                v-if="multiselect"
                :modelValue="computedSelectedOption(option)"
                class="select__item-checkbox"
              />
              {{ option.name }}
            </li>
          </ul>
        </slot>
        <slot name="options-footer"></slot>
      </div>
    </transition>
  </div>
</template>

<script>
// components
import SmCheckbox from '@/components/common/forms/SmCheckbox.vue';
export default {
  name: 'smSelect',

  components: {
    SmCheckbox,
  },

  props: {
    label: {
      type: String,
      // default: ''
    },

    placeholder: {
      type: String,
      // default: ''
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    value: {
      type: [String, Number, Boolean, Array, Object],
      // default: () => [],
    },

    options: {
      type: Array,
      default: () => [],
    },

    separator: {
      type: String,
      default: '; ',
    },

    search: {
      type: Boolean,
      default: false,
    },

    multiselect: {
      type: Boolean,
      default: false,
    },

    selectAll: {
      type: Boolean,
      default: false,
    },

    required: {
      type: Boolean,
      required: false,
      default: false,
    },

    clearable: {
      type: Boolean,
      default: false,
    },

    selectAllDisabled: {
      type: Boolean,
      default: false,
    },

    // Если необходимо, чтобы выпадающий список был плавующим на странице
    // FIXME: Учесть, что родитель может скроллиться
    float: {
      type: Boolean,
      required: false,
      default: false,
    },

    keySelection: {
      type: Boolean,
      default: true,
    },

    hideSelectAll: {
      type: Boolean,
      default: false,
    },

    hasMoreItems: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isOpen: false,
      searchValue: '',
      highlightedIndex: 0,
      dropdownWidth: null,
      processedOptions: [],
      selectedOptions: [],
      mouseOverIndex: null,
      keyMoveProcess: false,
    };
  },

  computed: {
    filteredOptions() {
      const validOptions =
        this.processedOptions.filter((option) => option.name !== null) || [];

      if (validOptions.length) {
        const words = this.searchValue.split(' ');

        if (words.length > 1) {
          return validOptions.filter((option) => {
            let includesCount = 0;
            words.forEach((word) => {
              if (
                String(option.name).toLowerCase().includes(word.toLowerCase())
              ) {
                includesCount += 1;
              }
            });

            return includesCount === words.length;
          });
        }

        return validOptions.filter((option) => {
          return String(option.name)
            .toLowerCase()
            .includes(this.searchValue.toLowerCase());
        });
      }

      return [];
    },

    isLargeList() {
      return !this.hasMoreItems && this.filteredOptions.length > 50;
    },

    currentPlaceholder() {
      if (!this.selectedOptions.length) {
        return this.placeholder;
      }

      if (this.multiselect) {
        if (this.selectedOptions.length) {
          return this.selectedOptions
            .map((item) => item.name)
            .join(this.separator);
        }

        return this.placeholder;
      }

      return this.selectedOptions[0]?.name || '';
    },

    isSelectedAll() {
      // Условие больше или равно нужно для больших списков, где при каждом поиске посылается запрос и список обновляется
      return (
        this.selectedOptions.length >= this.processedOptions.length &&
        this.processedOptions.every((option) => option.selected)
      );
    },
  },

  watch: {
    options: {
      handler() {
        if (this.multiselect) {
          this.processedOptions = this.options.map((item) => {
            if (this.value && this.value.includes(item.value)) {
              return {
                ...item,
                selected: true,
              };
            }

            return {
              ...item,
              selected: false,
            };
          });

          return;
        }
        this.processedOptions = this.options.map((item) => {
          if (this.value && this.value === item.value) {
            return {
              ...item,
              selected: true,
            };
          }

          return {
            ...item,
            selected: false,
          };
        });
      },
      deep: true,
      immediate: true,
    },

    value: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.processedOptions = this.options.map((item) => {
            if (
              (newVal || newVal === 0) &&
              ((Array.isArray(newVal) && newVal.includes(item.value)) ||
                newVal === item.value)
            ) {
              return {
                ...item,
                selected: true,
              };
            }

            return {
              ...item,
              selected: false,
            };
          });
        }
      },
      deep: true,
      immediate: true,
    },

    processedOptions: {
      handler(newVal) {
        if (Array.isArray(this.value) && !this.value.length)
          return (this.selectedOptions = []);

        if (this.multiselect) {
          const selectedItems = newVal.filter(
            (option) => option.selected === true
          );
          if (selectedItems && selectedItems.length) {
            selectedItems.forEach((option) => {
              if (
                !this.selectedOptions.some(
                  (item) => item.value === option.value
                )
              ) {
                this.selectedOptions.push(option);
              }
            });
          }
        } else {
          this.selectedOptions = newVal.filter(
            (option) => option.value === this.value
          );
          this.mouseOverIndex = null;
        }
      },
      deep: true,
      immediate: true,
    },

    isOpen(val, old) {
      if (val !== old && this.float) {
        const $app = document.querySelector('#app');
        const $dropdown = this.$refs.dropdown;
        const $selectWrapper = this.$refs.selectWrapper;

        if (val) {
          $selectWrapper.removeChild($dropdown);
          $app.appendChild($dropdown);

          $dropdown.style.top = `${
            this.$refs.selectWrapper.getBoundingClientRect().top +
            window.pageYOffset +
            this.$refs.selectWrapper.getBoundingClientRect().height
          }px`;
          $dropdown.style.left = `${
            this.$refs.selectWrapper.getBoundingClientRect().left +
            window.pageXOffset
          }px`;
          $dropdown.style.width = `${
            this.$refs.selectWrapper.getBoundingClientRect().width
          }px`;
        } else {
          $app.removeChild($dropdown);
          $selectWrapper.appendChild($dropdown);
        }
      }

      if (!this.keySelection) return;

      if (val) {
        document.documentElement.addEventListener('keydown', this.onKeyDown);
      } else {
        document.documentElement.removeEventListener('keydown', this.onKeyDown);
      }

      this.mouseOverIndex = null;
    },
  },

  beforeDestroy() {
    document.documentElement.removeEventListener('keydown', this.onKeyDown);
  },

  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
      this.searchValue = '';
      this.highlightedIndex = 0;

      if (this.isOpen) {
        this.$nextTick(() => {
          this.$refs.search.focus();
          this.scrollToTop();
        });
        return;
      }

      this.$nextTick(() => {
        this.$refs.select.focus();
      });
    },

    // ! ЭТО Отмена, а не закрытие
    close() {
      this.isOpen = false;
      this.searchValue = '';
      this.highlightedIndex = 0;
    },

    onSelect(option, index) {
      if (this.multiselect) {
        this.filteredOptions[index].selected =
          !this.filteredOptions[index].selected;

        if (!this.selectedOptions.some((item) => item.value === option.value)) {
          this.selectedOptions.push(option);
        } else {
          this.selectedOptions = this.selectedOptions.filter(
            (item) => item.value !== option.value
          );
        }

        const newVal = this.selectedOptions.map((option) => option.value);

        if (this.$refs.search) {
          this.$refs.search.focus();
        }

        this.$emit('input', newVal);
        this.$emit('update:value', newVal);
        this.$emit('select', this.selectedOptions);

        return;
      } else {
        this.selectedOptions = this.processedOptions.filter(
          (item) => item.value === option.value
        );
        this.toggle();

        this.$emit('input', option.value);
        this.$emit('update:value', option.value);
        this.$emit('select', option);
      }
    },

    onSelectAll() {
      if (this.isSelectedAll) {
        this.processedOptions.forEach((item) => {
          item.selected = false;
        });

        this.selectedOptions = this.selectedOptions.filter(
          (item) =>
            !this.processedOptions.some((option) => option.value === item.value)
        );
        this.$emit('input', []);
        this.$emit('select', []);
        this.$emit('selectAll', []);
        return;
      }

      this.processedOptions.forEach((item) => {
        item.selected = true;
        if (
          !this.selectedOptions.some((option) => option.value === item.value)
        ) {
          this.selectedOptions.push(item);
        }
      });
      const selectedOptionsValues = this.selectedOptions
        .filter((option) => option.selected)
        .map((option) => option.value);

      this.$emit('input', selectedOptionsValues);
      this.$emit('select', selectedOptionsValues);
      this.$emit('selectAll', selectedOptionsValues);
    },

    onMouseOver(optionIndex) {
      if (!this.keySelection || !this.isOpen || this.keyMoveProcess) return;

      this.mouseOverIndex = optionIndex;
    },

    onOptionsMouseMove() {
      if (!this.keySelection || !this.isOpen) return;

      this.keyMoveProcess = false;
    },

    onKeyDown(event) {
      if (event.code === 'Enter') {
        if (!this.keySelection) {
          this.toggle();
          return;
        }

        if (!this.isOpen) return;
        if (this.mouseOverIndex === null) return;

        if (this.mouseOverIndex === -1) {
          this.onSelectAll();
          return;
        }

        const option = this.filteredOptions[this.mouseOverIndex];

        if (!option) return;

        this.onSelect(option, this.mouseOverIndex);

        return;
      }

      if (!this.keySelection || !this.isOpen) return;

      if (event.code === 'ArrowDown') {
        if (this.mouseOverIndex === null) {
          this.mouseOverIndex = this.multiselect ? -1 : 0;
          return;
        }

        if (this.mouseOverIndex === this.filteredOptions.length - 1) return;

        event.preventDefault();
        this.mouseOverIndex++;
        this.keyMoveProcess = true;
        this.scrollIfNeeded();

        return;
      }

      if (event.code === 'ArrowUp') {
        if (this.mouseOverIndex === null) return;

        if (this.multiselect) {
          if (this.mouseOverIndex === -1) return;
        } else {
          if (this.mouseOverIndex === 0) return;
        }

        event.preventDefault();
        this.mouseOverIndex--;
        this.keyMoveProcess = true;
        this.scrollIfNeeded();
      }
    },

    scrollIfNeeded() {
      if (!this.keySelection || !this.isOpen) return;

      const options = this.$refs.options;
      const hoverItem = options.querySelector('.select__item--mouseover');

      if (!hoverItem) return;

      const optionsRect = options.getBoundingClientRect();
      const hoverItemRect = hoverItem.getBoundingClientRect();

      if (hoverItemRect.top < optionsRect.top + hoverItemRect.height) {
        options.scrollTo({
          top:
            options.scrollTop +
            (hoverItemRect.top - hoverItemRect.height - optionsRect.top),
          behavior: 'instant',
        });
      } else if (
        hoverItemRect.bottom + hoverItemRect.height >
        optionsRect.bottom
      ) {
        options.scrollTo({
          top:
            options.scrollTop +
            (hoverItemRect.bottom + hoverItemRect.height - optionsRect.bottom),
          behavior: 'instant',
        });
      }
    },

    computedSelectedOption(option) {
      if (this.multiselect) {
        return this.value && this.value.includes(option.value);
      }

      return this.value === option.value;
    },

    scrollToTop() {
      this.$refs.options.scrollTop = 0;
    },

    clearSelect() {
      this.selectedOptions = [];

      this.$emit('select', []);
      this.$emit('update:value', '');
      this.$emit('input', '');
    },
  },
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.35s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.select__wrapper {
  position: relative;

  width: 100%;
}

.select__wrapper--required .select {
  border-color: var(--red);
}

.select__label {
  display: inline-block;
  margin-bottom: 3px;

  color: var(--gray);
}

.select__inner-wrapper {
  position: relative;
}

.select {
  width: 100%;
  height: 36px;
  padding: 7px 25px 7px 12px;

  font-size: 16px;
  line-height: 1;
  text-align: left;

  background-color: var(--white);
  border: 1px solid var(--gray);
  border-radius: 10px;
  outline: none;

  cursor: pointer;

  &:focus {
    border: 1px solid var(--blue);
  }

  &:disabled {
    background-color: rgba(var(--rgb-gray), 0.2);

    // cursor: not-allowed;
  }

  &:disabled::placeholder {
    color: var(--black);
  }
}

.select__icon-button {
  position: absolute;
  right: 12px;
  top: 11px;

  line-height: 1;

  color: var(--gray);

  pointer-events: none;

  transition: transform 0.3s;
}

.select__icon-clear {
  position: absolute;
  right: 25px;
  top: 10px;
  padding: 0 5px 0 8px;

  line-height: 1;

  color: var(--gray);
  background-color: var(--white);

  transition: color 0.3s ease;

  cursor: pointer;

  &:hover {
    color: var(--blue);
  }
}

.select__dropdown {
  position: absolute;
  top: 100%;
  z-index: 99;

  width: 100%;
  margin: 5px 0;
  padding: 8px;

  background-color: var(--white);
  border: none;
  box-shadow: 0px 4px 11px rgb(0 0 0 / 20%);
}

.select__search-input {
  width: 100%;
  height: 36px;
  margin-bottom: 5px;
  padding: 8px 16px;

  background-color: var(--white);
  border: 1px solid var(--gray);
  border-radius: 5px;

  outline: none;

  &:focus {
    border: 1px solid var(--blue);
  }
}

.select__list {
  height: 100%;
  min-height: 20px;
  max-height: 300px;
  overflow-y: auto;

  line-height: 20px;

  overflow: auto;
}

.select__item {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  min-height: 36px;

  border-radius: 5px;

  cursor: pointer;

  &:hover {
    color: var(--white);

    background: var(--gray);
  }
}

.select__item--selected {
  color: var(--blue);
}

.select__item--mouseover {
  color: var(--white);
  background: var(--gray);
}

.select__item--hover-disabled {
  &:hover {
    color: inherit;
    background: inherit;
  }
}

.select__item-checkbox {
  margin-right: 5px;

  pointer-events: none;
}

.select__checkbox {
  pointer-events: none;
}

.select__search-input--hidden {
  position: absolute;

  width: 0;
  height: 0;

  appearance: none;
}
</style>
